/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { lang } from "store/slices/langSlice";

import EventCard from "components/Cards/EventCard";
import PaginationComponent from "../../components/PaginationComponent";

import styles from "./index.module.scss";

const EventList = ({
  type = "",
  getEvents,
  eventList,
  isEventListLoading,
  emptyListText,
  listReload = () => {},
  pageChangeHandler = () => {},
}) => {
  const language = useSelector(lang);

  return (
    <div className={styles.box}>
      {eventList?.content?.[language] && (
        <>
          <ul className={styles["box__list"]}>
            {eventList?.content?.[language]?.map((item) => {
              return (
                <li key={item.uuid} className={styles["box__list-item"]}>
                  <EventCard
                    type={type}
                    content={eventList.content}
                    getEvents={getEvents}
                    item={item}
                    onSuccess={() => {
                      listReload();
                    }}
                  />
                </li>
              );
            })}
          </ul>

          <div className={styles["box__list-pagination"]}>
            <PaginationComponent
              itemsPerPage={1}
              totalPages={eventList.totalPages}
              onChanePage={(page) => {
                pageChangeHandler(page);
              }}
            />
          </div>
        </>
      )}

      {!Boolean(eventList?.content?.[language]?.length) && !isEventListLoading && (
        <div className={styles["box__empty-text"]}>{emptyListText}</div>
      )}
    </div>
  );
};

export default EventList;
