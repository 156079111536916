import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserCryptoInfoMethod,
  getPeriodProfitMethod,
  downloadPeriodProfitMethod,
  getChildWalletsMethod,
  downloadChildWalletsMethod,
  getMainWalletsMethod,
  downloadMainWalletsMethod,
  getTransferFeeMethod,
} from "api/crypto";
import { checkIf2FAEnabledMethod } from "api/2FA";
import { saveAs } from "file-saver";
import { disable2Fa, enable2FA, getQRCode, validate2FA } from "../../api/2FA";
import { NotificationManager } from "react-notifications";

const initialState = {
  isLoading: false,
  error: null,
  info: null,
  profitTable: null,
  childWallets: null,
  mainWallets: null,
  qrCodeImage: null,
  secretKey: null,
  fee: null,
  isTwoFaEnabled: false,
};

export const getUserCryptoInfoAction = createAsyncThunk(
  "crypto/getUserCryptoInfoAction",
  async (data, { rejectWithValue }) => {
    try {
      const res = await getUserCryptoInfoMethod();
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPeriodProfitAction = createAsyncThunk(
  "crypto/getPeriodProfitAction",
  async (data, { rejectWithValue }) => {
    const { query, startDate, endDate } = data;
    try {
      const res = await getPeriodProfitMethod(query, startDate, endDate);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadPeriodProfitAction = createAsyncThunk(
  "crypto/downloadPeriodProfitAction",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const { status, data } = await downloadPeriodProfitMethod(startDate, endDate);
      if (status === 200 || status === 201) {
        saveAs(data, "PeriodProfit.xlsx");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMainWalletsAction = createAsyncThunk(
  "crypto/getMainWalletsAction",
  async (data, { rejectWithValue }) => {
    const { query, startDate, endDate, type } = data;
    try {
      const res = await getMainWalletsMethod(query, startDate, endDate, type);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadMainWalletsAction = createAsyncThunk(
  "crypto/downloadMainWalletsAction",
  async ({ query, startDate, endDate, type }, { rejectWithValue }) => {
    try {
      const { status, data } = await downloadMainWalletsMethod(query, startDate, endDate, type);
      if (status === 200 || status === 201) {
        saveAs(data, "MainWallets.xlsx");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getChildWalletsAction = createAsyncThunk(
  "crypto/getChildWalletsAction",
  async (data, { rejectWithValue }) => {
    const { query, startDate, endDate, type } = data;
    try {
      const res = await getChildWalletsMethod(query, startDate, endDate, type);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const downloadChildWalletsAction = createAsyncThunk(
  "crypto/downloadChildWalletsAction",
  async ({ query, startDate, endDate, type }, { rejectWithValue }) => {
    try {
      const { status, data } = await downloadChildWalletsMethod(query, startDate, endDate, type);
      if (status === 200 || status === 201) {
        saveAs(data, "ChildWallets.xlsx");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const generateTwoFaQrCodeAction = createAsyncThunk(
  "crypto/generateTwoFaQrCodeAction",
  async (_, { rejectWithValue }) => {
    try {
      const { status, data } = await getQRCode();
      if (status === 200 || status === 201) {
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const enableTwoFaAction = createAsyncThunk(
  "crypto/enableTwoFaAction",
  async ({ code, action }, { rejectWithValue }) => {
    try {
      const { status, data } = await enable2FA(code);
      if (status === 200 || status === 201) {
        action && action();
        return data;
      }
    } catch (error) {
      if (error.response.status === 404) {
        NotificationManager.error("", error?.response?.data.errorMessages[0], 3000);
        return;
      }
      NotificationManager.error("", error?.response?.data.message, 3000);
      return rejectWithValue(error);
    }
  },
);

export const disableTwoFaAction = createAsyncThunk(
  "crypto/disableTwoFaAction",
  async ({ action, code }, { rejectWithValue }) => {
    try {
      const { status, data } = await disable2Fa(code);
      if (status === 200 || status === 201) {
        action && action();
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateTwoFaAction = createAsyncThunk(
  "crypto/validateTwoFaAction",
  async ({ code, action }, { rejectWithValue }) => {
    try {
      const { status, data } = await validate2FA(code);
      if (status === 200 || status === 201) {
        action && action();
        return data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getTransferFeeAction = createAsyncThunk(
  "crypto/getTransferFeeAction",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getTransferFeeMethod(payload);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const checkIf2FAEnabledAction = createAsyncThunk(
  "crypto/checkIf2FAEnabledAction",
  async (_, { rejectWithValue }) => {
    try {
      const res = await checkIf2FAEnabledMethod();
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    clearFeeAction(state) {
      state.fee = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserCryptoInfoAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserCryptoInfoAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.info = action.payload;
        state.isTwoFaEnabled = action.payload.twoFactorAuthenticationEnabled;
      })
      .addCase(getUserCryptoInfoAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getPeriodProfitAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPeriodProfitAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profitTable = action.payload;
      })
      .addCase(getPeriodProfitAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(downloadPeriodProfitAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(downloadPeriodProfitAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(downloadPeriodProfitAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getMainWalletsAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMainWalletsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mainWallets = action.payload;
      })
      .addCase(getMainWalletsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(downloadMainWalletsAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(downloadMainWalletsAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(downloadMainWalletsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getChildWalletsAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getChildWalletsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.childWallets = action.payload;
      })
      .addCase(getChildWalletsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(downloadChildWalletsAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(downloadChildWalletsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.childWallets = action.payload;
      })
      .addCase(downloadChildWalletsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(enableTwoFaAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(enableTwoFaAction.fulfilled, (state) => {
        state.isLoading = false;
        state.twoFaQrCode = "";
      })
      .addCase(enableTwoFaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(disableTwoFaAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(disableTwoFaAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(disableTwoFaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(generateTwoFaQrCodeAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateTwoFaQrCodeAction.fulfilled, (state, action) => {
        const { qrCodeImage, secretKey } = action.payload;
        state.isLoading = false;
        state.qrCodeImage = qrCodeImage;
        state.secretKey = secretKey;
      })
      .addCase(generateTwoFaQrCodeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(validateTwoFaAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(validateTwoFaAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(validateTwoFaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getTransferFeeAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTransferFeeAction.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.fee = action.payload;
      })
      .addCase(getTransferFeeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(checkIf2FAEnabledAction.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkIf2FAEnabledAction.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isTwoFaEnabled = action.payload;
      })
      .addCase(checkIf2FAEnabledAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearFeeAction } = cryptoSlice.actions;

export const selectIsLoading = (state) => state.crypto?.isLoading;
export const selectError = (state) => state.crypto?.error;
export const selectInfo = (state) => state.crypto?.info;
export const selectPeriodProfit = (state) => state.crypto?.profitTable;
export const selectChildWallets = (state) => state.crypto?.childWallets;
export const selectMainWallets = (state) => state.crypto?.mainWallets;
export const selectQrCodeImg = (state) => state.crypto?.qrCodeImage;
export const selectSecretKey = (state) => state.crypto?.secretKey;
export const selectFee = (state) => state.crypto?.fee;
export const selectIsTwoFaEnabled = (state) => state.crypto?.isTwoFaEnabled;

export default cryptoSlice.reducer;
