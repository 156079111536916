import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutReq, signInAndCheckIf2faEnabledMethod, enable2FaOnSigninMethod, signinReq } from "../../api/auth";
import { NotificationManager } from "react-notifications";

const token = sessionStorage.getItem("token") || localStorage.getItem("token");

export const refreshToken = createAsyncThunk("auth/refresh", async (creds, { rejectWithValue }) => {
  try {
  } catch (error) {
    localStorage.removeItem("token");
    window.location = "/sign-in";
    return rejectWithValue(error.response.data.message);
  }
});

const initialState = {
  isAuthorized: !!token,
  isLoading: false,
  isError: null,
  userId: "",
};

export const signInAndCheckIf2faEnabledAction = createAsyncThunk(
  "auth/signInAndCheckIf2faEnabledAction",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      await signInAndCheckIf2faEnabledMethod(formData, token);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        NotificationManager.error("", `Помилка авторизації. Спробуйте ввести правильний логін або пароль!`, 5000);
      }
      return rejectWithValue({ status: error?.response?.status, data: error?.response?.data });
    }
  },
);

export const enable2FaOnSigninAction = createAsyncThunk(
  "auth/enable2FaOnSigninAction",
  async (credentials, { rejectWithValue }) => {
    try {
      const res = await enable2FaOnSigninMethod(credentials);
      if (res?.status === 200 || res?.status === 201) {
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("refreshToken", res.data?.refreshToken);

        return res.data;
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("", error?.response?.data?.errorMessages[0], 5000);
      rejectWithValue(error);
    }
  },
);

export const login = createAsyncThunk("auth/login", async (credentials, { rejectWithValue }) => {
  try {
    const response = await signinReq(credentials);
    !response && (await Promise.reject());
    if (response?.status === 200 || response?.status === 201) {
      localStorage.setItem("token", response.data?.token);
      localStorage.setItem("refreshToken", response.data?.refreshToken);

      return response.data;
    }
  } catch (error) {
    console.error(error);
    NotificationManager.error("", error?.response?.data?.errorMessages[0], 3000);
    return rejectWithValue(error.response.data.message);
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, { rejectWithValue }) => {
  try {
    const response = await logoutReq();
    !response && (await Promise.reject());
    if (response?.status === 200 || response?.status === 201) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    }
  } catch (error) {
    NotificationManager.error("", `Помилка виходу з адміністративної панелі!`, 3000);
    return rejectWithValue(error.response.data.message);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(signInAndCheckIf2faEnabledAction.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(signInAndCheckIf2faEnabledAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(signInAndCheckIf2faEnabledAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(enable2FaOnSigninAction.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(enable2FaOnSigninAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthorized = true;
        state.userId = action.payload.userId;
      })
      .addCase(enable2FaOnSigninAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthorized = true;
        state.userId = action.payload.userId;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthorized = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(refreshToken.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthorized = true;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      });
  },
});

const { reducer } = authSlice;

export const selectAuth = (state) => state.auth;
export const selectIsAuthorized = (state) => state.auth.isAuthorized;
export const selectAuthLoading = (state) => state.auth.isLoading;
export const selectAuthError = (state) => state.auth.isError;
export const selectLoggedUserId = (state) => state.auth.userId;

export default reducer;
