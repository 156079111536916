/* eslint-disable react-hooks/exhaustive-deps */
import Close from "assets/svg/Close";
import Button from "components/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import AddButton from "../../components/AddButton";
import ImageCard from "../../components/Cards/ImageCard";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import PaginationComponent from "../../components/PaginationComponent";
import {
  deleteImage,
  getImageList,
  selectImageLoading,
  selectImagesData,
} from "../../store/slices/image";
import FileUploadModal from "../FileUploadModal";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ImageList = ({
  isEdit,
  selectImageHandler,
  closeBtn,
  deleteImageHandler = () => {},
  editImageHandler = () => {},
  closeHandler = () => {},
}) => {
  const dispatch = useDispatch();
  const imagesData = useSelector(selectImagesData);
  const isLoading = useSelector(selectImageLoading);
  const [selectedPage, setSelectedPage] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imageDataToEdit, setImageDataToEdit] = useState(null);
  const { t } = useTranslation(["notification"]);

  const { control, handleSubmit, setValue, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });
  const searchWatch = watch("search");

  const getImgList = () => {
    if (searchWatch) {
      dispatch(
        getImageList({
          excludeData: 1,
          label: searchWatch,
          page: 0,
        })
      );
    }
    if (!searchWatch) {
      dispatch(
        getImageList({
          page: selectedPage,
          excludeData: 1,
        })
      );
    }
  };

  const deleteImg = (id) => {
    dispatch(deleteImage({ id }))
      .unwrap()
      .then((res) => {
        getImgList();
        deleteImageHandler();
      })
      .catch((err) => NotificationManager.error("", t("errorImageEdit"), 6000));
  };

  useEffect(() => {
    getImgList();
  }, [searchWatch]);

  return (
    <div className={styles["image-list"]}>
      <div className={styles["image-list__head"]}>
        <form onSubmit={handleSubmit(null)}>
          <Input
            control={control}
            name="search"
            type="text"
            role="search"
            size="sm-2"
          />
        </form>
        <AddButton
          onClick={(e) => {
            setIsOpenModal(true);
            e.preventDefault();
          }}
        />
        {closeBtn && (
          <Button type="clear" onClick={closeHandler}>
            <Close />
          </Button>
        )}
      </div>
      <div className={styles["image-list__body"]}>
        {Boolean(imagesData?.content?.length) && !isLoading && (
          <ul className={styles["image-list__body__list"]}>
            {imagesData?.content.map((item) => {
              return (
                <li
                  className={styles["image-list__body__list-item"]}
                  key={item.imageUuid}
                >
                  <ImageCard
                    data={item}
                    editView={isEdit}
                    disabledBtn={isLoading}
                    chooseBtnHandler={selectImageHandler}
                    deleteBtnHandler={deleteImg}
                    editBtnHandler={(data) => {
                      setImageDataToEdit(data);
                      setIsOpenModal(true);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {imagesData && (
          <div className={styles["image-list__body__pagination"]}>
            <PaginationComponent
              itemsPerPage={1}
              totalPages={imagesData.totalPages}
              onChanePage={(page) => {
                setSelectedPage(page - 1);
                dispatch(
                  getImageList({
                    page: page - 1,
                    label: searchWatch,
                    excludeData: 1,
                  })
                );
              }}
            />
          </div>
        )}
        {isLoading && <Loader />}
      </div>
      <FileUploadModal
        isOpen={isOpenModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        successHandler={(data) => {
          dispatch(getImageList());
          setImageDataToEdit(null);
          setValue("search", "");
        }}
        cancelHandler={() => {
          setImageDataToEdit(null);
        }}
        imageToEdit={imageDataToEdit}
      />
    </div>
  );
};

export default ImageList;
