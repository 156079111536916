/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "components/Button";
import LangComponent from "components/LangComponent";
import Loader from "components/Loader";
import TimeContainer from "components/TimeContainer";
import cx from "classnames";
import ModalEventEdit from "containers/ModalEventEdit";
import ImageListModal from "containers/ImageListModal";
import ImageContainer from "containers/ImageContainer";
import { parseDateToFormat } from "utils/date";
import { TextParser } from "utils/textParser";
import { numberFormat } from "utils/numberFormat";
import { useTranslation } from "react-i18next";
import moment from "moment";

import styles from "./index.module.scss";

const EvetnDataRow = ({
  title,
  description,
  isEditable = true,
  onClick,
  key,
}) => {
  return (
    <div className={styles["event__data-row"]} key={key}>
      <span className={styles["th"]}>{title}</span>
      <span className={styles["td"]}>
        <span className={styles["td-text"]}>
          {description === null ? "-" : description}
        </span>
        {isEditable && (
          <div className={styles["td-btn"]}>
            <Button type="edit" onClick={onClick} />
          </div>
        )}
      </span>
    </div>
  );
};

const EventDetail = ({
  data,
  requestData,
  showTimer,
  saveBtn = true,
  publishBtn,
  cancelBtn,
  deleteBtn = true,
  setData = () => {},
  setRequestData = () => {},
  saveHandler = () => {},
  publishHandler = () => {},
  cancelHandler = () => {},
  deleteHandler = () => {},
  onFieldChange = () => {},
  saveWithPublish = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState(data);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditImageModal, setShowEditImageModal] = useState(false);
  const [editedValue, setEditedValue] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedFieldLabel, setEditedFieldLabel] = useState("");
  const [editedFieldType, setEditedFieldType] = useState("textarea");
  const { t } = useTranslation(["button", "event"]);

  const editField = (value, name, type, label) => {
    setEditedValue(value);
    setEditedFieldName(name);
    setEditedFieldType(type);
    setEditedFieldLabel(label);
    setShowEditModal(true);
  };

  useEffect(() => {
    setEventData(data);

    setRequestData(data);
  }, [data]);

  return (
    <div className={styles["event"]}>
      <div className={styles["content"]}>
        <div className={styles["event__head"]}>
          <div className={styles["event__head-img"]}>
            <ImageContainer imageLink={eventData?.imageLink} imageUuid={eventData?.imageUuid ?? eventData?.uk?.imageUuid} />
          </div>
          <div className={styles["event__head-action"]}>
            <div className={styles["event__head-action-btns"]}>
              <div className={styles["btn"]}>
                <Button
                  type="edit"
                  width={38}
                  height={38}
                  onClick={() => {
                    setShowEditImageModal(true);
                  }}
                />
              </div>

              {saveBtn && (
                <div className={styles["btn"]}>
                  <Button onClick={() => saveHandler(requestData)}>
                    {t("button:save")}
                  </Button>
                </div>
              )}

              {publishBtn && (
                <div className={styles["btn"]}>
                  <Button
                    onClick={() => {
                      saveWithPublish({
                        ...eventData,
                        startReference: Number(eventData.startReference),
                        controlReference: Number(eventData.controlReference),
                        startTime: parseDateToFormat(eventData.startTime),
                        betEndTime: parseDateToFormat(eventData.betEndTime),
                        finishTime: parseDateToFormat(eventData.finishTime),
                      });
                    }}
                  >
                    {t("button:publish")}
                  </Button>
                </div>
              )}

              {cancelBtn && (
                <div className={styles["btn"]}>
                  <Button onClick={cancelHandler}>
                    {t("button:cancelEvent")}
                  </Button>
                </div>
              )}

              {deleteBtn && (
                <div className={cx(styles["btn"], styles["delete-btn"])}>
                  <Button type="delete" onClick={deleteHandler} />
                </div>
              )}
            </div>

            {showTimer && (
              <div className={styles["timer-wrap"]}>
                <TimeContainer
                  createTime={moment(eventData.startTime).subtract(10, "day")}
                  betEndTime={eventData.betEndTime}
                  startTime={eventData.startTime}
                  finishTime={eventData.finishTime}
                  viewType="pageType"
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles["event__data"]}>
          <EvetnDataRow
            title={t("event:subCategory")}
            description={eventData.subCategory}
            onClick={() => {
              editField(
                eventData.subCategory,
                "subCategory",
                "textarea",
                t("event:subCategory")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:eventDescription")}
            description={TextParser(eventData.title)}
            onClick={() => {
              editField(
                eventData.title,
                "title",
                "textarea",
                t("event:eventDescription")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:eventAdditionalInfo")}
            description={TextParser(eventData.additionalInfo)}
            onClick={() => {
              editField(
                eventData.additionalInfo,
                "additionalInfo",
                "textarea",
                t("event:eventAdditionalInfo")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:startTime")}
            description={parseDateToFormat(
              eventData.startTime,
              "DD.MM.YYYY HH:mm UTCZ"
            )}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(
                eventData.startTime,
                "startTime",
                "date",
                t("event:startTime")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:betEndTime")}
            description={parseDateToFormat(
              eventData.betEndTime,
              "DD.MM.YYYY HH:mm UTCZ"
            )}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(
                eventData.betEndTime,
                "betEndTime",
                "date",
                t("event:betEndTime")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:finishTime")}
            description={parseDateToFormat(
              eventData.finishTime,
              "DD.MM.YYYY HH:mm UTCZ"
            )}
            isEditable={data.status !== "PUBLISHED"}
            onClick={() => {
              editField(
                eventData.finishTime,
                "finishTime",
                "date",
                t("event:finishTime")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:startReference")}
            description={numberFormat(eventData.startReference)}
            onClick={() => {
              editField(
                eventData.startReference,
                "startReference",
                "number",
                t("event:startReference")
              );
            }}
          />
          <EvetnDataRow
            title={t("event:controlReference")}
            description={numberFormat(eventData.controlReference)}
            onClick={() => {
              editField(
                eventData.controlReference,
                "controlReference",
                "number",
                t("event:controlReference")
              );
            }}
          />
        </div>

        <LangComponent eventList viewType="vertical" />
      </div>

      {isLoading && <Loader />}

      <ModalEventEdit
        type={editedFieldType}
        editedValue={editedValue}
        fieldName={editedFieldName}
        isOpen={showEditModal}
        setIsOpenModal={() => setShowEditModal(!showEditModal)}
        successHandler={(fieldName, value) => {
          setEventData({
            ...eventData,
            [fieldName]: value,
          });
          setRequestData({
            ...eventData,
            [fieldName]: value,
          });
          setData({
            ...eventData,
            [fieldName]: value,
          });
          onFieldChange(fieldName, value);
        }}
        title={editedFieldLabel}
      />

      <ImageListModal
        isOpen={showEditImageModal}
        setIsOpenModal={() => setShowEditImageModal(!showEditImageModal)}
        successHandler={(image) => {
          setEventData({
            ...eventData,
            imageUuid: image,
          });
          setData({
            ...eventData,
            imageUuid: image,
          });
          onFieldChange("imageUuid", image);
        }}
      />
    </div>
  );
};

export default EventDetail;
