import React from "react";
import styles from "../index.module.scss";
import cx from "classnames";
import Input from "components/Input";
import { useForm, useWatch } from "react-hook-form";

const BalanceCol = ({ data, headers, setUserBalancePinCodeModal, setUserBalanceTransactionDetails }) => {
  const { control, handleSubmit } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      add_balance: "",
      minus_balance: "",
    },
  });

  const { add_balance, minus_balance } = useWatch({ control });

  const submitHandler = (operation) => {
    if (add_balance || minus_balance) {
      setUserBalanceTransactionDetails({
        operation,
        username: data.username.replace(/\s/g, ""),
        addedAmount: add_balance,
        subtractedAmount: minus_balance,
      });
      setUserBalancePinCodeModal(true);
    }
  };

  return headers.map((header, index) => (
    <td
      key={header.key}
      className={cx({
        [styles["col-disabled"]]: data.disabled,
        [styles["col-form"]]: header.key === "add_balance" || header.key === "minus_balance",
      })}
    >
      {header.key === "add_balance" || header.key === "minus_balance" ? (
        <form onSubmit={handleSubmit(null)}>
          <Input
            control={control}
            name={header.key}
            id={header.key}
            role="balance"
            type="number"
            onSubmit={() => {
              submitHandler(header.key);
            }}
          />
        </form>
      ) : !data[header.key] ? (
        <span>-</span>
      ) : (
        <span>{data[header.key]}</span>
      )}
    </td>
  ));
};

export default BalanceCol;
