import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  cancelEventReq,
  closeEventReq,
  createEventReq,
  deleteEventReq,
  expireEventReq,
  getAllEventReq,
  getEventReq,
  getExpiredEventReq,
  getFilteredEventReq,
  getFinishedEventReq,
  publishEventReq,
  updateEventReq,
  getUnPublishEventReq,
  stopEventReq,
  delayEventExpiryReq,
} from "../../api/event";
import { LANGS } from "constants/lang";
import { toasterCreator } from "../../utils/toasterCreator";
import { getEventNotificationsReq } from "../../api/bunch";

const initialState = {
  event: null,
  eventList: {
    content: { en: [], de: [], fr: [], uk: [], pl: [], es: [], kk: [] },
  },
  isEventLoading: false,
  eventWaitingUpdate: null,
};

export const eventDataMutationFromResponse = (resData) => {
  const setLangData = (value) => {
    const localized = {};
    Object.keys(LANGS).forEach((key) => {
      localized[key] = value;
    });
    return localized;
  };

  return {
    title: setLangData(resData.title),
    startTime: resData.startTime,
    betEndTime: resData.betEndTime,
    finishTime: resData.finishTime,
    subCategory: setLangData(resData.subCategory),
    imageUuid: resData.imageUuid,
    additionalInfo: setLangData(resData.additionalInfo),
    startReference: setLangData(resData.startReference),
    controlReference: setLangData(resData.controlReference),
  };
};

export const eventDataMutationForEventDetails = (stepsData, categoryUuid, langValue) => {
  // TASK: TEMPORARY PAYLOAD FORMAT UNTIL THE LOCALIZATION FUNCTIONALITY IS IMPLEMENTED ON THE BACKEND
  // TODO: REFACTOR STORED DATA FORMAT FROM ARRAY TO OBJECT?
  let getData;

  if (stepsData.length) {
    getData = (key) => stepsData.find((item) => item.key === key)?.value;
  }

  // getData =

  let data = {};

  if (categoryUuid) {
    data = { categoryUuid };
  }

  return {
    ...data,
    title: getData("title")[langValue],
    startTime: getData("dates").startTime,
    betEndTime: getData("dates").betEndTime,
    finishTime: getData("dates").finishTime,
    subCategory: getData("subCategory")[langValue],
    imageUuid: getData("imageUuid"),
    additionalInfo: getData("additionalInfo")[langValue],
    startReference: Number(getData("startReference")[langValue]) || null,
    controlReference: Number(getData("controlReference")[langValue]) || null,
  };
};

export const eventDataMutationForRequest = (stepsData, categoryUuid, langValue) => {
  const getData = (key) => stepsData.find((item) => item.key === key)?.value;

  let data = {};

  if (categoryUuid) {
    data = { categoryUuid };
  }

  const langs = ["uk", "en", "de", "fr", "es", "kk", "pl"];

  const result = langs.reduce((acc, lang) => {
    acc[lang] = {
      ...data,
      title: getData("title")[lang],
      startTime: getData("dates").startTime,
      betEndTime: getData("dates").betEndTime,
      finishTime: getData("dates").finishTime,
      subCategory: getData("subCategory")[lang],
      imageUuid: getData("imageUuid"),
      additionalInfo: getData("additionalInfo")[lang],
      startReference: Number(getData("startReference")[lang]) || null,
      controlReference: Number(getData("controlReference")[langValue]) || null,
    };
    return acc;
  }, {});

  return result;
};

export const eventDataMutationFromStoreToEdit = (stepsData, categoryUuid) => {
  const getData = (key) => stepsData.find((item) => item.key === key)?.value;
  let data = {};

  if (categoryUuid) {
    data = { categoryUuid };
  }

  return {
    ...data,
    title: getData("title"),
    startTime: getData("dates").startTime,
    betEndTime: getData("dates").betEndTime,
    finishTime: getData("dates").finishTime,
    subCategory: getData("subCategory"),
    imageUuid: getData("imageUuid"),
    additionalInfo: getData("additionalInfo"),
    startReference: getData("startReference") || null,
    controlReference: getData("controlReference") || null,
  };
};

export const updateEventDataByKey = (key, value, id) => {
  return {
    type: "event/UPDATE_EVENT_DATA_BY_KEY",
    key,
    value,
    id,
  };
};

export const clearEventState = () => {
  return {
    type: "event/CLEAR_STATE",
  };
};

export const createEvent = createAsyncThunk("event/create", async (data, { rejectWithValue }) => {
  try {
    const response = await createEventReq(data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.eventCreated();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteEvent = createAsyncThunk("event/delete", async (data, { rejectWithValue }) => {
  try {
    const response = await deleteEventReq(data.id);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const updateEvent = createAsyncThunk("event/update", async (data, { rejectWithValue }) => {
  try {
    const response = await updateEventReq(data.id, data.data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.eventUpdated();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const publishEvent = createAsyncThunk("event/publish", async (data, { rejectWithValue }) => {
  try {
    const response = await publishEventReq(data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.eventPublished();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const expireEvent = createAsyncThunk("event/expire", async (data, { rejectWithValue }) => {
  try {
    const response = await expireEventReq(data);
    if (response.status === 200 || response.status === 201) {
      data?.type === "calculating"
        ? toasterCreator.events.expirationFixed()
        : toasterCreator.events.expirationSuccess();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const closeEvent = createAsyncThunk("event/close", async (data, { rejectWithValue }) => {
  try {
    const response = await closeEventReq(data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.eventCalculated();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const cancelEvent = createAsyncThunk("event/cancel", async (data, { rejectWithValue }) => {
  try {
    const response = await cancelEventReq(data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.eventCanceled();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getEvent = createAsyncThunk("event/getEvent", async (data, { rejectWithValue }) => {
  try {
    const response = await getEventReq(data.id);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getFilteredEvent = createAsyncThunk("event/getFilteredEvent", async (data, { rejectWithValue }) => {
  try {
    const response = await getFilteredEventReq(data.params, data.category);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getAllEvent = createAsyncThunk("event/getAllEvent", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllEventReq();
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getFinishedEvent = createAsyncThunk("event/getFinishedEvent", async (data, { rejectWithValue }) => {
  try {
    const response = await getFinishedEventReq(data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getExpiredEvent = createAsyncThunk("event/getExpiredEvent", async (_, { rejectWithValue }) => {
  try {
    const response = await getExpiredEventReq();
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getUnPublishedEvent = createAsyncThunk("event/getUnPublishedEvent", async (data, { rejectWithValue }) => {
  try {
    const response = await getUnPublishEventReq(data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getEventNotifications = createAsyncThunk("event/getEventNotifications", async (_, { rejectWithValue }) => {
  try {
    const response = await getEventNotificationsReq();
    if (response.status === 200) {
      const onToaster = (key = "") => {
        if (key === "FINISHED") return toasterCreator.events.eventNeedExpiration();
        if (key === "EXPIRED") return toasterCreator.events.eventNeedCalculateUsers();
      };

      Object.keys(response.data).map((key) => onToaster(key));
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const stopEvent = createAsyncThunk("event/stopEvent", async (data, { rejectWithValue }) => {
  try {
    const { eventUuid, reasonObject } = data;
    const response = await stopEventReq(eventUuid, reasonObject);
    if (response.status === 200 || response.status === 201) {
      // toasterCreator.events.eventStopped();  // TO BE CREATED LATER
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const delayEventExpiry = createAsyncThunk("event/delayEventExpiry", async (data, { rejectWithValue }) => {
  try {
    const { eventDTO } = data;
    const response = await delayEventExpiryReq(eventDTO.uuid, data);
    if (response.status === 200 || response.status === 201) {
      // toasterCreator.events.eventUpdated();  // TO BE CREATED LATER
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase("event/UPDATE_EVENT_DATA_BY_KEY", (state, action) => {
      const { key, value, id } = action;
      const lang = localStorage.getItem("lang");

      const index = state.eventList.content[lang].findIndex((event) => event.uuid === id);

      if (index >= 0) {
        state.eventList.content[lang][index][key] = value;
      }
    });

    builder.addCase("event/CLEAR_STATE", (state) => {
      state.eventList = {
        content: { en: [], de: [], fr: [], uk: [], pl: [], es: [], kk: [] },
      };
    });

    builder
      .addCase(createEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(createEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(deleteEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(updateEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(updateEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(publishEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(publishEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(publishEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(expireEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(expireEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(expireEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(closeEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(closeEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(closeEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(cancelEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(cancelEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(cancelEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.isEventLoading = false;
      })
      .addCase(getEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getFilteredEvent.pending, (state) => {
        state.isEventLoading = true;
      })

      .addCase(getFilteredEvent.fulfilled, (state, action) => {
        const lang = localStorage.getItem("lang");

        const { content } = action.payload;
        const updatedContent = { ...state.eventList.content };

        updatedContent[lang] = content;

        const payloadWithoutContent = { ...action.payload };
        delete payloadWithoutContent["content"];

        state.isEventLoading = false;
        state.eventList = {
          ...payloadWithoutContent,
          content: updatedContent,
        };
      })

      .addCase(getFilteredEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getAllEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(getAllEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(getAllEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getFinishedEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(getFinishedEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
        state.eventList = action.payload;
      })
      .addCase(getFinishedEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getExpiredEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(getExpiredEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
        state.eventList = action.payload;
      })
      .addCase(getExpiredEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(getUnPublishedEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(getUnPublishedEvent.fulfilled, (state, action) => {
        const lang = localStorage.getItem("lang");

        const { content } = action.payload;
        const updatedContent = { ...state.eventList.content };
        updatedContent[lang] = content;

        const payloadWithoutContent = { ...action.payload };
        delete payloadWithoutContent["content"];

        state.isEventLoading = false;
        state.eventList = {
          ...payloadWithoutContent,
          content: updatedContent,
        };
      })
      .addCase(getUnPublishedEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(stopEvent.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(stopEvent.fulfilled, (state, action) => {
        state.isEventLoading = false;
        state.eventWaitingUpdate = action.payload;
      })
      .addCase(stopEvent.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase(delayEventExpiry.pending, (state) => {
        state.isEventLoading = true;
      })
      .addCase(delayEventExpiry.fulfilled, (state, action) => {
        state.isEventLoading = false;
      })
      .addCase(delayEventExpiry.rejected, (state) => {
        state.isEventLoading = false;
      })

      .addCase("event/REMOVE_FROM_LIST", (state, action) => {
        state.eventList = state.eventList.filter((item) => item.uuid !== action.id);
      });
  },
});

export const selectIsEventLoading = (state) => state.event?.isEventLoading;
export const selectEventInfo = (state) => state.event?.event;
export const selectEventList = (state) => state.event?.eventList;
export const selectEventsContent = (state) => state.event?.eventList?.content;
export const selectEventWaitingUpdate = (state) => state.event?.eventWaitingUpdate;

export default eventSlice.reducer;
