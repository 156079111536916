import { createSlice, original } from "@reduxjs/toolkit";
import { toasterCreator } from "../../utils/toasterCreator";

const defaultState = [
  {
    id: 1,
    name: "Під категорія",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 2,
    name: "Опис події",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 3,
    name: "Зміст події",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 4,
    name: "Часові значення",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 5,
    name: "Початкове значення",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 6,
    name: "Граничне значення",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
  {
    id: 7,
    name: "Зображення",
    isDirty: false,
    isValid: false,
    isVisited: false,
    isStored: false,
  },
];

const defaultStepPageData = [
  {
    id: 1,
    key: "subCategory",
    value: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  },
  {
    id: 2,
    key: "title",
    value: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  },
  {
    id: 3,
    key: "additionalInfo",
    value: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  },
  {
    id: 4,
    key: "dates",
    value: {
      startTime: "",
      betEndTime: "",
      finishTime: "",
      timeZone: "",
    },
  },
  {
    id: 5,
    key: "startReference",
    value: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  },
  {
    id: 6,
    key: "controlReference",
    value: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
    },
  },
  {
    id: 7,
    key: "imageUuid",
    value: "",
  },
];

const initialState = {
  stepsItems: defaultState,
  stepsPages: [],
  stepsCategoryId: "",
  isReadyToSubmit: false,
};

export const markItemAsDirty = (id, value) => {
  return {
    type: "steps/MARK_AS_DIRTY",
    id,
    value,
  };
};

export const markItemAsValid = (id, value) => {
  return {
    type: "steps/MARK_AS_VALID",
    id,
    value,
  };
};

export const markItemAsVisited = (id) => {
  return {
    type: "steps/MARK_AS_VISITED",
    id,
  };
};

export const setToDefault = () => {
  return {
    type: "steps/SET_STEP_TO_DEFAULT",
  };
};

export const setStepPageData = (id, value) => {
  return {
    type: "steps/SET_STEP_PAGE_DATA",
    id,
    value,
  };
};

export const setStepPageNumberData = (id, value) => {
  return {
    type: "steps/SET_STEP_PAGE_NUMBER_DATA",
    id,
    value,
  };
};

export const updateStepPageDataByKey = (key, value) => {
  return {
    type: "steps/UPDATE_STEP_PAGE_DATA_BY_KEY",
    key,
    value,
  };
};

export const saveStepPageToStore = (id, selectedCategory) => {
  toasterCreator.events.savedStep()
  return {
    type: "steps/SAVE_STEP_PAGE_TO_STORE",
    id,
    selectedCategory,
  };
};

export const setCategoryId = (id) => {
  return {
    type: "steps/SET_CATEGORY_ID",
    id,
  };
};

export const initPageData = () => {
  return {
    type: "steps/INIT_STEPS_PAGES_DATA",
  };
};

export const clearPagesReduxState = () => {
  return {
    type: "steps/CLEAR_PAGES_REDUX_STATE",
  };
};

export const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase("steps/SET_CATEGORY_ID", (state, action) => {
      state.stepsCategoryId = action.id;
    });
    builder.addCase("steps/MARK_AS_DIRTY", (state, action) => {
      state.stepsItems = state.stepsItems.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            isDirty: action.value,
          };
        }
        return item;
      });
    });
    builder.addCase("steps/MARK_AS_VALID", (state, action) => {
      state.stepsItems = state.stepsItems.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            isValid: action.value,
          };
        }
        return item;
      });
    });
    builder.addCase("steps/MARK_AS_VISITED", (state, action) => {
      state.stepsItems = state.stepsItems.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            isVisited: true,
          };
        }
        return item;
      });
    });
    builder.addCase("steps/SET_STEP_PAGE_DATA", (state, action) => {
      state.stepsPages = state.stepsPages.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            value: action.value,
          };
        }
        return item;
      });
    });
    builder.addCase("steps/SET_STEP_PAGE_NUMBER_DATA", (state, action) => {
      state.stepsPages = state.stepsPages.map((item) => {
        if (item.id === action.id) {
          const newValue = Object.keys(action.value).reduce((acc, lang) => {
            acc[lang] = action.value.uk;
            return acc;
          }, {});

          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      });
    });
    builder.addCase("steps/UPDATE_STEP_PAGE_DATA_BY_KEY", (state, action) => {
      const lang = localStorage.getItem("lang");
      state.stepsPages = state.stepsPages.map((item) => {
        const isDateValues =
          action.key === "betEndTime" ||
          action.key === "finishTime" ||
          action.key === "startTime";

        if (isDateValues && item.key === "dates") {
          return {
            ...item,
            value: {
              ...item.value,
              [action.key]: action.value,
            },
          };
        }

        if (item.key === action.key) {
          let value = {};

          if (item.key === "imageUuid") {
            value = action.value;
          } else if (
            item.key === "startReference" ||
            item.key === "controlReference"
          ) {
            for (const key of Object.keys(item.value)) {
              value[key] = action.value;
            }
          } else {
            value = { ...item.value, [lang]: action.value };
          }

          return {
            ...item,
            value,
          };
        }

        return item;
      });
      sessionStorage.setItem("event_steps", JSON.stringify(state.stepsPages));
    });
    builder.addCase("steps/SAVE_STEP_PAGE_TO_STORE", (state, action) => {
      state.stepsItems = state.stepsItems.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            isStored: true,
          };
        }
        return item;
      });

      if (action.id === 7) {
        state.isReadyToSubmit = true;
        sessionStorage.setItem("event_ready_to_submit", true);
      }

      state.stepsCategoryId = action.selectedCategory;
      sessionStorage.setItem("event_category_id", state.stepsCategoryId);

      const targetPage = original(state.stepsPages).find(
        (item) => item.id === action.id
      );
      let storedData = sessionStorage.getItem("event_steps");

      if (!storedData) {
        return sessionStorage.setItem(
          "event_steps",
          JSON.stringify([targetPage])
        );
      }

      storedData = JSON.parse(storedData);
      const checkIsTargetPageiInStore = storedData.find(
        (item) => item.id === targetPage.id
      );

      if (!checkIsTargetPageiInStore) {
        return sessionStorage.setItem(
          "event_steps",
          JSON.stringify([...storedData, targetPage])
        );
      }
      storedData = storedData.map((item) => {
        if (item.id === targetPage.id) {
          return targetPage;
        } else {
          return item;
        }
      });
      sessionStorage.setItem("event_steps", JSON.stringify(storedData));
    });
    builder.addCase("steps/SET_STEP_TO_DEFAULT", (state, action) => {
      state.stepsItems = [...defaultState];
      state.stepsPages = [...defaultStepPageData];
      state.stepsCategoryId = "";
      state.isReadyToSubmit = false;
      sessionStorage.clear("event_category_id");
      sessionStorage.clear("event_steps");
    });
    builder.addCase("steps/CLEAR_PAGES_REDUX_STATE", (state, action) => {
      state.stepsItems = [...defaultState];
      state.stepsPages = [...defaultStepPageData];
      state.isReadyToSubmit = false;
      state.stepsCategoryId = "";
    });
    builder.addCase("steps/INIT_STEPS_PAGES_DATA", (state, action) => {
      const storedCategoryId = sessionStorage.getItem("event_category_id");
      const storedData = sessionStorage.getItem("event_steps");
      const storedReadyToSubmit = sessionStorage.getItem(
        "event_ready_to_submit"
      );
      state.stepsCategoryId = storedCategoryId;
      state.isReadyToSubmit = storedReadyToSubmit;

      if (!storedData) {
        state.stepsPages = [...defaultStepPageData];
      } else {
        const data = JSON.parse(storedData);
        state.stepsItems = defaultState.map((item) => {
          const storedPageItem = data.find((page) => page.id === item.id);
          if (storedPageItem) {
            return {
              ...item,
              isValid: true,
              isVisited: true,
              isStored: true,
            };
          } else {
            return item;
          }
        });
        state.stepsPages = defaultStepPageData.map((item) => {
          const storedPageItem = data.find((page) => page.id === item.id);
          if (storedPageItem) {
            return storedPageItem;
          } else {
            return item;
          }
        });
      }
    });
  },
});

export const selectStepsItems = (state) => state.steps?.stepsItems;
export const selectStepsPages = (state) => state.steps?.stepsPages;
export const selectIsReadyToSubmit = (state) => state.steps?.isReadyToSubmit;
export const selectStoredCategoryId = (state) => state.steps?.stepsCategoryId;

export default stepsSlice.reducer;
