export const getFileType = (name) => {
  if (!name) {
    return;
  }

  const splitted = name.split(".")?.reverse();

  if (splitted.length) {
    return splitted[0];
  } else {
    return "";
  }
};
