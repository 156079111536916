import { NotificationManager } from "react-notifications";

export const copyToBuffer = (textToCopy) => {
  if (textToCopy) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy);
      NotificationManager.success(
        "",
        `Текст скопійовано до буферу обміну.`,
        6000
      );
    } else {
      const textField = document.createElement("textarea");
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      NotificationManager.success(
        "",
        `Текст скопійовано до буферу обміну.`,
        6000
      );
    }
  }
};
