import ModalWarning, { ErrorText } from "containers/ModalWarning";
import React, { useState } from "react";
import Button from "../../Button";
import ImageContainer from "containers/ImageContainer";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const ImageCard = ({
  data,
  key,
  editView = false,
  disabledBtn,
  chooseBtnHandler = () => {},
  editBtnHandler = () => {},
  deleteBtnHandler = () => {},
}) => {
  const [isOpenWarnModal, setIsOpenWarnModal] = useState(false);
  const { t } = useTranslation(["button"]);

  return (
    <div className={styles["image-card"]} key={key}>
      <div className={styles["image-card__img"]}>
        <ImageContainer imageLink={data.imageLink} imageUuid={data.imageUuid} />
      </div>
      <div className={styles["image-card__desc"]}>
        <span className={styles["image-card__desc-title"]}>{data.label}</span>
        <div className={styles["image-card__desc-tools"]}>
          {!editView && (
            <Button
              disabled={disabledBtn}
              onClick={() => {
                chooseBtnHandler(data.imageUuid);
              }}
            >
              {t("button:select")}
            </Button>
          )}
          {editView && (
            <>
              <Button
                type="edit"
                width={38}
                height={38}
                onClick={() => editBtnHandler(data)}
                disabled={disabledBtn}
              />
              <Button
                type="delete"
                width={38}
                height={38}
                onClick={() => setIsOpenWarnModal(true)}
                disabled={disabledBtn}
              />
            </>
          )}
        </div>
      </div>
      <ModalWarning
        isOpen={isOpenWarnModal}
        setIsOpenModal={() => setIsOpenWarnModal(!isOpenWarnModal)}
        successHandler={() => {
          deleteBtnHandler(data.imageUuid);
        }}
        title={
          <span>
            <Trans i18nKey="modals:deleteImageConfirm">
              Ти точно впевнений, що хочеш{" "}
              <ErrorText>Видалити зображення</ErrorText>?
            </Trans>
          </span>
        }
      />
    </div>
  );
};

export default ImageCard;
