import { Parser } from "simple-text-parser";

const TextParser = (text) => {
  const parser = new Parser();
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const lineBreak = /\r?\n/g;
  parser.addRule(expression, function (tag, clean_tag) {
    const html = `<a target="_blank" class="link" href=${tag}>${tag}</a>`;
    return { type: "tag", text: html, value: clean_tag };
  });
  parser.addRule(lineBreak, function (tag, clean_tag) {
    const html = `<br/>`;
    return { type: "tag", text: html, value: clean_tag };
  });
  return <div dangerouslySetInnerHTML={{ __html: parser.render(text) }} />;
};
export { TextParser };
