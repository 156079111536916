import React, { createContext, useContext } from "react";
import { withTranslation, useTranslation } from "react-i18next";

const I18nContext = createContext();

export const useTranslations = () => {
  return useContext(I18nContext);
};

export const useTranslationsNamespace = (ns, component) => {
  return withTranslation(ns)(component);
};

export function I18nProvider({ children }) {
  const { t, i18n } = useTranslation();

  return (
    <I18nContext.Provider value={{ t: t, i18n: i18n }}>
      {children}
    </I18nContext.Provider>
  );
}
