import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AuthGuard from "./containers/AuthGuard";
import { selectLoggedUserRole, saveLoggedUserRole } from "store/slices/users";
import { selectIsTwoFaEnabled } from "store/slices/crypto";

import Page from "./components/Page";
import Login from "./pages/Login";
import Home from "./pages/Home";

import "./index.scss";
import { PRIVAT_ROUTES } from "routes/privatRoutes";
import { Toaster } from "react-hot-toast";

import jwtDecode from "jwt-decode";

function App() {
  const auth = useSelector((state) => state.auth.isAuthorized);
  const isTwoFaEnabled = useSelector(selectIsTwoFaEnabled);
  const loggedUserRole = useSelector(selectLoggedUserRole);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const dispatch = useDispatch();

  const decodeToken = () => {
    try {
      const decoded = jwtDecode(token);
      dispatch(saveLoggedUserRole(decoded.roles[1]));
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  useEffect(() => {
    decodeToken();
  }, [token]);

  return (
    <>
      <Page isAuth={auth} isTwoFaEnabled={isTwoFaEnabled} loggedUserRole={loggedUserRole}>
        <Routes>
          <Route path="/">
            {!auth && <Route index element={<Navigate to="sign-in" state={{ from: location }} replace />} />}
            {!auth && <Route exact path="sign-in" element={<Login />} />}
            <Route element={<AuthGuard isAuth={auth} />}>
              <Route exact index element={<Home />} />
              {PRIVAT_ROUTES.map((route, index) => (
                <Route key={index + route.id} exact={route.exact} path={route.path} element={route.component} />
              ))}
            </Route>
            <Route path="*" element={<Navigate to="/" state={{ from: location }} replace />} />
          </Route>
        </Routes>
      </Page>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
