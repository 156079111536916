import React, { useState } from "react";
import { useController } from "react-hook-form";
import cx from "classnames";

import styles from "./index.module.scss";
import Complete from "assets/svg/Complete";

const InputControl = (props) => {
  const {
    size = "md",
    isPlaceholderWhite,
    role,
    label,
    id,
    bordered,
    centered = false,
    onClick = () => {},
    inForm,
    onSubmit
  } = props;
  const { field } = useController(props);

  // TODO: REPLACE THIS LOGIC AFTER INTEGRATION
  const [isCompleted, setCompleted] = useState(false);
  const makeCompleted = () => {
    onSubmit && onSubmit()
    setCompleted(true);

    setTimeout(() => {
      setCompleted(false);
    }, 2000);
  };

  return (
    <>
      <div
        className={cx(styles.input, {
          [styles[`form-input`]]: inForm,
          [styles[`${size}`]]: size,
          [styles["placeholder-white"]]: isPlaceholderWhite,
          [styles["is-empty"]]: !field.value,
          [styles[`${role}`]]: role,
          [styles.bordered]: bordered,
          [styles.centered]: centered,
        })}
      >
        {label && (
          <label
            className={cx({
              [styles["form-label"]]: inForm,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div className={styles["input__wrap"]}>
          <input
            className={cx({
              [styles["inForm"]]: inForm,
            })}
            {...props}
            {...field}
            value={field.value}
            onBlur={() => {
              field.onBlur();
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onChange={(e) => {
              field.onChange(e);
            }}
          />
          {role === "search" && <i></i>}
          {role === "balance" && (
            <>
              <div
                className={cx(styles.complete, {
                  [styles.completed]: isCompleted,
                })}
                onClick={() => {
                  makeCompleted();
                  onClick();
                }}
              >
                <Complete />
              </div>
              <span
                style={{
                  opacity: isCompleted ? 1 : 0,
                  zIndex: isCompleted ? 1 : -1,
                  fontSize: "16px",
                  lineHeight: "18px",
                }}
              >
                Completed
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const InputWithoutControl = (props) => {
  const {
    size = "md",
    isPlaceholderWhite,
    role,
    value,
    label,
    id,
    bordered,
    centered = false,
  } = props;

  return (
    <>
      <div
        className={cx(styles.input, {
          [styles[`${size}`]]: size,
          [styles["placeholder__white"]]: isPlaceholderWhite,
          [styles["is-empty"]]: !value,
          [styles[`${role}`]]: role,
          [styles.bordered]: bordered,
          [styles.centered]: centered,
        })}
      >
        {label && <label htmlFor={id}>{label}</label>}
        <div className={styles["input__wrap"]}>
          <input {...props} />
          {role === "search" && <i></i>}
        </div>
      </div>
    </>
  );
};

const Input = (props) => {
  if (props.control) {
    return <InputControl {...props} />;
  }

  return <InputWithoutControl {...props} />;
};

export default Input;
