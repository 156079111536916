import Events from "pages/Events";
import ActiveEvent from "pages/Events/ActiveEvent";
import AddEvent from "pages/Events/AddEvent";
import CalculatedEvent from "pages/Events/CalculatedEvent";
import DeletedEvent from "pages/Events/DeletedEvent";
import ExpireEvent from "pages/Events/ExpireEvent";
import FinishedEvents from "pages/Events/FinishedEvents";
import PublishedEvent from "pages/Events/PublishedEvent";
import PublishedEventEdit from "pages/Events/PublishedEventEdit";
import UnpublishedEvent from "pages/Events/UnpublishedEvents";
import UnpublishedEventEdit from "pages/Events/UnpublishedEventsEdit";
import ImagePage from "pages/Images";
import NotificationPage from "pages/Notification";
import AddNotification from "pages/Notification/AddNotification";
import EditNotification from "pages/Notification/EditNotification";
import UsersPage from "../pages/Users";
import EditBalance from "pages/EditBalance";
import AdministratorsPage from "pages/Administrators";
import CryptoPage from "pages/Crypto";
import SettingsPage from "pages/Settings";
import StatByEvent from "pages/StatByEvent";

export const PRIVAT_ROUTES = [
  {
    path: "events",
    id: "events",
    exact: true,
    component: <Events />,
    title: "Events",
  },
  {
    path: "events/new-event",
    id: "new-event",
    exact: true,
    component: (
      <Events>
        <AddEvent />
      </Events>
    ),
    title: "Add Event",
  },
  {
    path: "events/unpublished-event",
    id: "unpublished-event",
    exact: true,
    component: (
      <Events>
        <UnpublishedEvent />
      </Events>
    ),
    title: "Unpublished Events",
  },
  {
    path: "events/unpublished-event/edit/:id",
    id: "unpublished-event-edit",
    exact: true,
    component: (
      <Events>
        <UnpublishedEventEdit />
      </Events>
    ),
    title: "Edit Unpublished Event",
  },
  {
    path: "events/published",
    id: "published-event",
    exact: true,
    component: (
      <Events>
        <PublishedEvent />
      </Events>
    ),
    title: "Published Event",
  },
  {
    path: "events/published/edit/:id",
    id: "published-event",
    exact: true,
    component: (
      <Events>
        <PublishedEventEdit />
      </Events>
    ),
    title: "Published Event",
  },
  {
    path: "events/active",
    id: "active-event",
    exact: true,
    component: (
      <Events>
        <ActiveEvent />
      </Events>
    ),
    title: "Active Event",
  },
  {
    path: "events/expirate-event",
    id: "expirate-event",
    exact: true,
    component: (
      <Events>
        <ExpireEvent />
      </Events>
    ),
    title: "Expirate Event",
  },
  {
    path: "events/calculate-event",
    id: "calculate-event",
    exact: true,
    component: (
      <Events>
        <CalculatedEvent />
      </Events>
    ),
    title: "Calculate Event",
  },
  {
    path: "events/finished-events",
    id: "finished-events",
    exact: true,
    component: (
      <Events>
        <FinishedEvents />
      </Events>
    ),
    title: "Finished events",
  },
  {
    path: "events/deleted-events",
    id: "deleted-events",
    exact: true,
    component: (
      <Events>
        <DeletedEvent />
      </Events>
    ),
    title: "Deleted events",
  },
  {
    path: "images",
    id: "images",
    exact: true,
    component: <ImagePage />,
    title: "Images",
  },
  {
    path: "notification",
    id: "notification",
    exact: true,
    component: <NotificationPage />,
    title: "Notification",
  },
  {
    path: "notification/new",
    id: "notification",
    exact: true,
    component: <AddNotification />,
    title: "Notification",
  },
  {
    path: "notification/edit/:id",
    id: "notification",
    exact: true,
    component: <EditNotification />,
    title: "Notification",
  },
  {
    path: "users",
    id: "users",
    exact: true,
    component: <UsersPage />,
    title: "Users",
  },
  {
    path: "balance/edit",
    id: "balance",
    exact: true,
    component: <EditBalance />,
    title: "Edit Balance",
  },
  {
    path: "admins",
    id: "admins",
    exact: true,
    component: <AdministratorsPage />,
    title: "Administrators",
  },
  {
    path: "crypto",
    id: "crypto",
    exact: true,
    component: <CryptoPage />,
    title: "Crypto Processing",
  },
  {
    path: "settings",
    id: "settings",
    exact: true,
    component: <SettingsPage />,
    title: "Settings",
  },
  {
    path: "eventStat",
    id: "eventStat",
    exact: true,
    component: <StatByEvent />,
    title: "Stat By Event",
  },
];
